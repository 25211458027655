<template>
  <div class="d-flex justify-content-start mr-1 mb-1">
    <a :href="file.file" class="file-details" target="_blank">
      <b-icon-file-earmark-arrow-up />
      <p class="m-0">{{ file.name }}</p>
    </a>
  </div>
</template>

<script>
export default {
  props: ['file'],
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/pages/file-view.scss';
</style>
