<template>
  <b-row class="deal-comments">
    <b-col cols="12" lg="8" md="7">
      <b-card>
        <app-timeline v-if="comments.length">
          <template v-for="comment in comments">
            <app-timeline-item
              :key="comment.id"
              :class="{ 'flag-comment': comment.is_flag }"
            >
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <small class="timeline-item-time text-nowrap text-muted">{{
                  comment.manager_full_name
                }}</small>
                <small class="timeline-item-time text-nowrap text-muted">{{
                  new Date(comment.created).toLocaleString('en-GB')
                }}</small>
              </div>
              <b-row>
                <b-col
                  class="d-flex flex-wrap justify-content-start align-items-center"
                  md="9"
                >
                  <p class="mb-0">{{ comment.message }}</p>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col
                  class="d-flex flex-wrap justify-content-start align-items-center"
                  md="12"
                >
                  <template v-for="item in comment.attachments">
                    <FileView :key="item.id" :file="item" />
                  </template>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <b-form-checkbox
                    v-model="comment.is_flag"
                    button
                    button-variant="none"
                    class="btn-icon"
                    size="sm"
                    @change="highlightComment(comment.id, comment.is_flag)"
                  >
                    <b-icon-exclamation-circle-fill
                      :variant="comment.is_flag ? 'danger' : 'dark'"
                    />
                  </b-form-checkbox>
                  <b-button
                    class="btn-icon"
                    size="sm"
                    variant="flat-success"
                    @click="openModal(comment)"
                  >
                    <b-icon-pencil />
                  </b-button>
                  <b-button
                    class="btn-icon"
                    size="sm"
                    variant="flat-danger"
                    @click="deleteComment(comment.id)"
                  >
                    <b-icon-trash />
                  </b-button>
                </b-col>
              </b-row>
            </app-timeline-item>
          </template>
        </app-timeline>
        <span v-else>Нет комментариев</span>
      </b-card>
    </b-col>
    <b-col cols="12" lg="4" md="5">
      <b-card>
        <b-form-group label="Комментарий">
          <b-form-textarea v-model="commentMessage" />
          <b-form-file
            v-model="attachments"
            class="mt-2"
            multiple
            placeholder="Выберите файлы"
          />
        </b-form-group>
        <b-form-group label="Контрольная дата">
          <b-input-group>
            <b-form-input
              v-model="controlDate"
              placeholder="ГГГГ-ММ-ДД"
              type="text"
            />
            <b-input-group-append>
              <b-datepicker
                v-model="controlDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                button-only
                reset-button
                right
                size="sm"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button class="mt-1" @click="addComment"> Добавить</b-button>
      </b-card>
    </b-col>
    <comment-modal
      :comment-data="selectedComment"
      @commentUpdated="getComments"
    />
  </b-row>
</template>
<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem'
import CommentModal from '@/views/deals/components/DealCommentEditModal'
import FileView from '@/views/deals/components/FileView'

export default {
  components: { FileView, CommentModal, AppTimelineItem, AppTimeline },
  props: ['dealNumber'],
  data() {
    return {
      deal: this.dealNumber || this.$route.params.id,
      controlDate: null,
      commentMessage: '',
      comments: [],
      attachments: [],
      selectedComment: {},
    }
  },
  created() {
    this.getComments()
  },
  methods: {
    async getComments() {
      const res = await this.$http.get(`admin/deal-comments/?deal=${this.deal}`)
      this.comments = res.data.results
    },
    async addComment() {
      const res = await this.$http.post('admin/deal-comments/', {
        deal: this.deal,
        message: this.commentMessage,
        control_date: this.controlDate,
      })
      for (const item of this.attachments) {
        const formData = new FormData()
        formData.append('comment', res.data.id)
        formData.append('name', item.name)
        formData.append('file', item)
        await this.$http.post('admin/deal-attachments/', formData)
      }
      this.comment = ''
      this.controlDate = null
      this.attachments = []
      await this.getComments()
    },
    async deleteComment(id) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите удалить комментарий?', {
          title: 'Подтвердите',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http.delete(`admin/deal-comments/${id}/`)
            await this.getComments()
          }
        })
    },
    openModal(item) {
      this.selectedComment = item
      this.$bvModal.show('comment-modal')
    },
    async highlightComment(id, value) {
      await this.$http.patch(`admin/deal-comments/${id}/`, {
        is_flag: value,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.flag-comment {
  border-radius: 10px;
  padding-top: 5px;
  background: lightyellow;
}
</style>
