<template>
  <b-modal
    id="comment-modal"
    centered
    ok-only
    title="Изменить комментарий"
    @ok="updateComment"
  >
    <b-card-text>
      <b-form-group label="Комментарий">
        <b-form-textarea v-model="commentForm.message" />
        <b-row class="mt-2">
          <b-col
            v-for="item in commentForm.attachments"
            :key="item.id"
            class="d-flex justify-content-start align-items-center"
          >
            <FileView :file="item" />
            <b-button
              class="btn-icon h-50"
              size="sm"
              variant="flat-danger"
              @click="deleteDoc(item.id)"
            >
              <b-icon-trash />
            </b-button>
          </b-col>
        </b-row>
        <b-form-file
          v-model="attachments"
          class="mt-2"
          multiple
          placeholder="Выберите файлы"
        />
        <b-form-group label="Контрольная дата">
          <b-input-group>
            <b-form-input
              v-model="commentForm.control_date"
              placeholder="ГГГГ-ММ-ДД"
              type="text"
            />
            <b-input-group-append>
              <b-datepicker
                v-model="commentForm.control_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                button-only
                reset-button
                right
                size="sm"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-checkbox v-model="commentForm.is_flag" class="mt-2"
          >Пометить комментарий как важный
        </b-checkbox>
      </b-form-group>
    </b-card-text>
  </b-modal>
</template>

<script>
import FileView from '@/views/deals/components/FileView'

export default {
  name: 'CommentModal',
  components: { FileView },
  props: ['commentData'],
  data() {
    return {
      commentForm: {},
      attachments: [],
    }
  },
  watch: {
    commentData() {
      this.commentForm = this.commentData
    },
  },
  methods: {
    async updateComment() {
      await this.$http.patch(`admin/deal-comments/${this.commentForm.id}/`, {
        deal: this.commentForm.deal,
        message: this.commentForm.message,
        is_flag: this.commentForm.is_flag,
        control_date: this.commentForm.control_date === "" ? null : this.commentForm.control_date,
      })
      for (const item of this.attachments) {
        const formData = new FormData()
        formData.append('comment', this.commentForm.id)
        formData.append('name', item.name)
        formData.append('file', item)
        await this.$http.post(`admin/deal-attachments/`, formData)
      }
      this.$emit('commentUpdated')
    },
    async deleteDoc(id) {
      await this.$http.delete(`admin/deal-attachments/${id}/`)
      const res = await this.$http.get(
        `admin/deal-comments/${this.commentForm.id}/`
      )
      this.commentForm.attachments = res.data.attachments
    },
  },
}
</script>
